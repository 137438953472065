import { createGlobalStyle } from "styled-components";

import { colors } from "./colors";
import { breakpoints } from "./spacing";
import close from "../images/icons/close.svg";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    min-height: 100%;
  }

  body {
    width: 100%;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;

    font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5;

    * {
      box-sizing: border-box;

      &::selection {
        color: white;
        background: ${colors.grey02};
      }
    }
  }

  /*
  A workaround for forcing accessibility wrappers
  to have a 100% height.
  Reach Router issue here: https: //github.com/reach/router/issues/63
  */
  #___gatsby,
  div[role="group"][tabindex] {
    height: 100%;
    min-height: 100% !important;
  }

  .carousel.carousel-slider {
    height: 100%;
  }

  .carousel .slider {
    height: 100%;
  }

  .carousel .slide {
    height: 100%;
  }

  .carousel .control-dots .dot {
    position: relative;
    width: 48px !important;
    height: 48px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    opacity: 1 !important;
    background: transparent !important;
    outline: 0;

    &::after {
      border-radius: 0 !important;
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      opacity: 1 !important;
      background: #F2F2F2 !important;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .carousel .control-dots .dot.selected {
    background: transparent !important;

    &::after {
      border-radius: 0 !important;
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      opacity: 1 !important;
      background: #1dc0da !important;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  /*  React-modal */

  .ReactModalPortal {
    z-index: 9999;
    position: relative;
  }

  .ReactModal__Overlay {
    height: 100vh;
    width: 100vw;
    z-index: 10;
    pointer-events: all;
    padding: 50px 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ReactModal__Overlay::before {
    content: "";
    display: inline-block;
    background: url(${close});
    width: 20px;
    height: 20px;
    background-size: 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
  }

  .ReactModal__Content {
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1000px;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    .ReactModal__Overlay {
      padding: 50px 40px 30px;
    }

    .ReactModal__Content {
      height: auto;
      max-height: 85vh;
      max-width: 80vw;
    }

    .ReactModal__Overlay::before {
      top: 40px;
      right: 40px;
    }
  }

  @media (min-width: 1024px) {
    .ReactModal__Content {
      max-height: 90vh;
    }
  }

  @media (min-width: 1200px) {
    .ReactModal__Overlay::before {
      width: 25px;
      height: 25px;
      background-size: 25px;
    }

    .ReactModal__Content {
      max-width: 90vw;
    }
  }

  .optanon-show-settings-popup-wrapper {
    height: auto !important;

    .optanon-show-settings-button {
      box-sizing: border-box !important;
    }

    .optanon-show-settings-middle {
      height: auto !important;
      box-sizing: border-box !important;
      background-color: transparent !important;
      border: 0;
      padding: 0;
      float: none;

      .optanon-show-settings {
        box-sizing: border-box !important;
      }
    }
  }

  .optanon-show-settings-popup-wrapper, .optanon-show-settings-popup-wrapper *, .optanon-show-settings-popup-wrapper div, .optanon-show-settings-popup-wrapper span, .optanon-show-settings-popup-wrapper ul, .optanon-show-settings-popup-wrapper li, .optanon-show-settings-popup-wrapper a, .optanon-show-settings-popup-wrapper p, #optanon-show-settings-popup ul, #optanon-show-settings-popup li, #optanon-show-settings-popup a, #optanon-show-settings-popup span {
    font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif !important;
  }
`;

export const Typography = createGlobalStyle`
  html {
    font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    scroll-behavior: smooth;
  }

  h1 {
    font-size: 32px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 46px;
      letter-spacing: -1px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 72px;
      letter-spacing: -1.5px;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 40px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 48px;
      letter-spacing: -1px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 28px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 20px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 24px;
    }
  }

  h5 {
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
    font-size: 0.95em;
  }

  h6 {
    font-size: 13px;
    line-height: 1.1;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 14px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 14px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 17px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 18px;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  button {
    font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif;
  }

  button.tab {
    padding: 0 0 4px 0;
    background: none;
    border: none;
    font-size: 1rem;
    font-weight: normal;
    color: ${colors.grey03};

    &.selected {
      font-weight: bold;
      border-bottom: 3px solid ${colors.black01};
      color: ${colors.black01};
    }
  }

  strong {
    font-weight: bold;
  }
`;
