import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { GlobalStyle, Typography } from "../../theme/global";
import Navigation from "./Navigation";
import Footer from "./Footer";

const Container = styled.div`
  ${props =>
    props.padded &&
    css`
      padding-top: 80px;
    `}

  ${props =>
    props.isModal &&
    css`
      height: 10vh;
      overflow: hidden;
    `}
`;

const Helper = styled.div`
  position: fixed;
  width: 0;
  height: 100vh;
  top: 0;
  left: -1px;
`;

const Layout = ({
  children,
  navigationTransparent = true,
  padded = false,
  isModal = false,
}) => {
  return (
    <>
      <Container padded={padded}>
        <GlobalStyle />
        <Typography />
        {!isModal && <Navigation transparent={navigationTransparent} />}
        {children}
        {!isModal && <Footer />}
      </Container>
      <Helper id="mobile-helper" />
    </>
  );
  }

Layout.propTypes = {
  navigationTransparent: PropTypes.bool,
  padded: PropTypes.bool,
  isModal: PropTypes.bool,
};

export default Layout;
