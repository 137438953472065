import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  margin: 0 auto;
  max-width: 72.5rem;
  padding: 0 20px;
`

const Inner = ({
  children,
  direction = "column",
  align = "flex-start",
  justify = "flex-start",
  ...restProps
}) => (
  <Container
    direction={direction}
    align={align}
    justify={justify}
    {...restProps}
  >
    {children}
  </Container>
)

Inner.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["row", "column"]),
}

export default Inner
