import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { Grid, Col, Row } from "react-styled-flexboxgrid";

import Logo from "../../images/logo_dxtra-health.inline.svg";
import linkedInIco from "../../images/icons/linked-in-logo-white.svg";
import instagramIco from "../../images/icons/instagram-logo-white.svg";
import youtubeIco from "../../images/icons/youtube-logo-white.svg";

import Spacer from "./Spacer";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 60px 0 40px;
  background-color: ${(props) => props.theme.colors.black01};
  color: ${(props) => props.theme.colors.white};
  z-index: ${(props) => props.theme.zIndex.section};
`;

const Divider = styled.hr`
  margin: 0;
  border-width: 0 0 1px;
  border-bottom-style: solid;
  border-color: rgba(255, 255, 255, 0.3);
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 170px;
  margin: 32px 0 28px;
  padding: 0;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 0;
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints.tablet}px) and (-ms-high-contrast: none) {
    display: block;
    float: right;

    &::after {
      content: "";
      display: table;
      clear: both;
      float: none;
    }
  }
`;

const MenuItem = styled.li`
  display: flex;
  margin-left: 0;
  flex: 0.5;
  flex-basis: 50%;
  margin-bottom: 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: flex;
    flex: initial;
    flex-basis: initial;
    margin-left: ${(props) => props.theme.spacing.xxl};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tabletLandscape}px) {
    margin-left: 40px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: ${(props) => (props.variant === "social" ? "none" : "block")};
  }
`;

const NavigationItem = styled(Link)`
  border-bottom: 3px solid transparent;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: 14px;
  line-height: 1.1;
  padding: 10px 0;
  display: block;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    &:hover,
    &.active {
      border-bottom: 3px solid ${(props) => props.theme.colors.white};
    }
  }
`;

const Notice = styled.p`
  margin: 20px 0 0;
  padding: 32px 0;
  font-size: 11px;
  border-width: 1px 0 0;
  border-top-style: solid;
  border-color: rgba(255, 255, 255, 0.3);
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 0;
    margin: 0 40px 0 0;
    border: 0;
    text-align: left;
  }
`;

const ExternalLink = styled.a`
  font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif !important;
  border-bottom: 3px solid transparent;
  color: ${(props) => props.theme.colors.white} !important;
  display: block;
  cursor: pointer;
  padding: 0 0 25px;
  font-size: 11px;
  text-decoration: none;
  transition: color 150ms ease-in-out;
  margin: 0;

  &:hover {
    color: ${(props) => props.theme.colors.grey03} !important;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 0;
  }
`;

const InternalLink = styled(Link)`
  border-bottom: 3px solid transparent;
  color: ${(props) => props.theme.colors.white} !important;
  cursor: pointer;
  display: block;
  padding: 0 0 25px;
  font-size: 11px;
  text-decoration: none;
  transition: color 150ms ease-in-out;
  margin: 0;

  &:hover {
    color: ${(props) => props.theme.colors.grey03} !important;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 0;
  }
`;

const ReversableRow = styled(Row)`
  flex-direction: column-reverse;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
  }
`;

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialLinkContainer = styled.div`
  display: ${(props) => (props.variant === "medium" ? "none" : "flex")};
  justify-content: flex-end;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: ${(props) => (props.variant === "small" ? "none" : "flex")};
  }
`;

const SocialLink = styled.a`
  ${(props) =>
    props.variant === "linkedin" &&
    css`
      background-image: url(${linkedInIco});
    `}

  ${(props) =>
    props.variant === "instagram" &&
    css`
      background-image: url(${instagramIco});
    `}

  ${(props) =>
    props.variant === "youtube" &&
    css`
      background-image: url(${youtubeIco});
    `}

  background-repeat: no-repeat;
  background-size: 23px;
  display: block;
  width: 23px;
  height: 23px;
  margin: 5px 15px 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 0 0 0 15px;
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Container>
      <Grid>
        <Row>
          <FlexCol xs={12}>
            <Link to="/">
              <Logo width={132} />
            </Link>
            <nav>
              <Menu>
                <MenuItem>
                  <NavigationItem to="/our-brands" activeClassName="active">
                    Our Brands
                  </NavigationItem>
                </MenuItem>
                <MenuItem>
                  <NavigationItem to="/join-us" activeClassName="active">
                    Join Us
                  </NavigationItem>
                </MenuItem>
                <MenuItem>
                  <NavigationItem to="/contact" activeClassName="active">
                    Contact Us
                  </NavigationItem>
                </MenuItem>
                <MenuItem variant="social">
                  <SocialLinkContainer variant="small">
                    <SocialLink
                      href="https://www.linkedin.com/company/ipg-dxtra-health/"
                      variant="linkedin"
                    />
                    <SocialLink
                      href="https://www.instagram.com/ipgdxtrahealth"
                      variant="instagram"
                    />
                    <SocialLink
                      href="https://www.youtube.com/channel/UC9fev_Hz15JOy_tiyz2MQQw"
                      variant="youtube"
                    />
                  </SocialLinkContainer>
                </MenuItem>
              </Menu>
            </nav>
          </FlexCol>
        </Row>
        <Row>
          <Col xs={12}>
            <Spacer margin={{ xs: "0", m: "64px 0 32px" }}>
              <Divider />
            </Spacer>
          </Col>
        </Row>
        <ReversableRow>
          <Col xs={12} sm={3} lg={3}>
            <Notice>&copy; {year} IPG DXTRA &bull; All Rights Reserved</Notice>
          </Col>
          <Col xs={12} sm={6} lg={7}>
            <nav>
              <Menu>
                <MenuItem>
                  <ExternalLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.interpublic.com/"
                  >
                    Part of IPG
                  </ExternalLink>
                </MenuItem>
                <MenuItem>
                  <InternalLink to="/terms-of-use">Terms of Use</InternalLink>
                </MenuItem>
                <MenuItem>
                  <InternalLink to="/gdpr">GDPR</InternalLink>
                </MenuItem>
                <MenuItem>
                  <InternalLink to="/privacy-notice">
                    Privacy Notice
                  </InternalLink>
                </MenuItem>
                <MenuItem>
                  <InternalLink to="/ccpa-privacy-notice">
                    CCPA Privacy Notice
                  </InternalLink>
                </MenuItem>
                <MenuItem>
                  <span className="optanon-show-settings">
                    <ExternalLink>Cookie Settings</ExternalLink>
                  </span>
                </MenuItem>
                <MenuItem>
                  <InternalLink to="/modern-slavery-act">
                    Modern Slavery Act
                  </InternalLink>
                </MenuItem>
              </Menu>
            </nav>
          </Col>
          <Col xs={12} sm={3} lg={2}>
            <SocialLinkContainer variant="medium">
              <SocialLink
                href="https://www.linkedin.com/company/ipg-dxtra-health/"
                target="_blank"
                variant="linkedin"
              />
              <SocialLink
                href="https://www.instagram.com/ipgdxtrahealth"
                target="_blank"
                variant="instagram"
              />
              <SocialLink
                href="https://www.youtube.com/channel/UC9fev_Hz15JOy_tiyz2MQQw"
                target="_blank"
                variant="youtube"
              />
            </SocialLinkContainer>
          </Col>
        </ReversableRow>
      </Grid>
    </Container>
  );
};

export default Footer;
