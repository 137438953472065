import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import { useRecoilValue } from "recoil";

import { getWindowScroll, browserState } from './ScrollAware';

import Inner from "./Inner";

import Logo from "../../images/logo_dxtra-health.inline.svg";
import BurgerButton from "./BurgerButton";
import Spacer from "./Spacer";

const Container = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.navigation};
  background-color: ${props =>
    props.transparent ? "transparent" : props.theme.colors.black01};
  transition: background-color 250ms;

`;

const NavigationWrapper = styled(Inner)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

const MenuItem = styled.li`
  margin-top: 32px;
  margin-left: 40px;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    margin-top: 0;
  }
`;

const NavigationItem = styled(Link)`
  display: inline-block;
  border-bottom: 3px solid transparent;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  font-size: 16px;
  line-height: 1.1;
  padding: 10px 0;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;

  &:hover,
  &.active {
    border-bottom: 3px solid ${props => props.theme.colors.white};
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    font-size: 14px;
  }
`;

const DesktopNav = styled.nav`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    display: inline-block;
  }
`;

const MobileNav = styled.nav`
  display: inline-block;
  background: ${props => props.theme.colors.black01};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: transform 150ms ease-out;
  transform: translateX(${props => (props.isOpen ? 0 : "-100vw")});
  clip-path: polygon(100% 0%, 100% 70%, 0% 160%, 0 0%);

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const Navigation = ({ transparent = true }) => {
  const { isMobile } = useRecoilValue(browserState);
  const [isTransparent, setIsTransparent] = useState(transparent);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const onScroll = () => {
    const scrollY = Math.max(0, getWindowScroll().y);
    if (transparent) {
      setIsTransparent(scrollY < (isMobile ? 60 : 140));
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [onScroll, transparent]);

  return (
    <Container transparent={isTransparent}>
      <NavigationWrapper>
        <Link to="/">
          <Logo width={150} />
        </Link>
        <DesktopNav>
          <Menu>
            <MenuItem>
              <NavigationItem to="/our-brands" activeClassName="active">
                Our Brands
              </NavigationItem>
            </MenuItem>
            <MenuItem>
              <NavigationItem to="/careers" activeClassName="active">
                Join Us
              </NavigationItem>
            </MenuItem>
            <MenuItem>
              <NavigationItem to="/contact" activeClassName="active">
                Contact Us
              </NavigationItem>
            </MenuItem>
          </Menu>
        </DesktopNav>
      </NavigationWrapper>

      <MobileNav isOpen={showMobileNav}>
        <Menu>
          <MenuItem>
            <Spacer margin={{ xs: "0 0 12px" }}>
              <Link to="/">
                <Logo width={132} />
              </Link>
            </Spacer>
          </MenuItem>
          <MenuItem>
            <NavigationItem to="/our-brands" activeClassName="active">
              Our Brands
            </NavigationItem>
          </MenuItem>
          <MenuItem>
            <NavigationItem to="/careers" activeClassName="active">
              Join Us
            </NavigationItem>
          </MenuItem>
          <MenuItem>
            <NavigationItem to="/contact" activeClassName="active">
              Contact Us
            </NavigationItem>
          </MenuItem>
        </Menu>
      </MobileNav>

      <BurgerButton
        onClick={() => setShowMobileNav(!showMobileNav)}
        isOpen={showMobileNav}
      />
    </Container>
  );
};

Navigation.propTypes = {
  transparent: PropTypes.bool,
};

export default Navigation;
