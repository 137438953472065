import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Bun = styled.button`
  position: fixed;
  left: 25px;
  bottom: 25px;
  background-color: ${props => props.theme.colors.black01};
  border: 0;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  padding: 16px 15px 13px;
  pointer-events: all;
  z-index: ${props => props.theme.zIndex.top};

  &:focus {
    outline: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const Cheese = styled.span`
  display: inline-block;
  height: 24px;
  position: relative;
  width: 28px;
`;

const Patty = styled.span`
  background-color: ${props => props.theme.colors.white};
  border-radius: 2px;
  display: block;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 28px;

  ::before {
    background-color: ${props => props.theme.colors.white};
    border-radius: 2px;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: -9px;
    width: 28px;
    transition: transform 150ms ease-in-out;

    ${props =>
      props.isOpen &&
      css`
        top: 0;
        transform: rotate(45deg);
      `}
  }
  ::after {
    background-color: ${props => props.theme.colors.white};
    border-radius: 2px;
    bottom: -9px;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    width: 28px;
    transition: transform 150ms ease-in-out;

    ${props =>
      props.isOpen &&
      css`
        bottom: -2px;
        transform: rotate(-45deg);
      `}
  }

  ${props =>
    props.isOpen
      ? css`
          height: 0;
        `
      : css`
          height: 2px;
        `}
`;

const BurgerButton = ({ onClick, isOpen = false }) => (
  <Bun
    aria-controls="navigation"
    aria-label="Menu"
    isOpen={isOpen}
    onClick={onClick}
    type="button"
  >
    <Cheese isOpen={isOpen}>
      <Patty isOpen={isOpen} />
    </Cheese>
  </Bun>
);

BurgerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default BurgerButton;
