import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { withMargin, withPadding } from "../../theme/spacing";

const Container = styled.div`
  display: ${props => (props.flex ? "flex" : "block")};
  flex-wrap: ${props => (props.shouldWrap ? "wrap" : "")};

  ${props => withMargin({ margin: props.margin.xs })}
  ${props => withPadding({ padding: props.padding.xs })}

  @media (min-width: ${props => props.theme.breakpoints.mobile}px) {
    ${props => props.margin.s && withMargin({ margin: props.margin.s })}
    ${props => props.padding.s && withPadding({ padding: props.padding.s })}
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    ${props => props.margin.m && withMargin({ margin: props.margin.m })}
    ${props => props.padding.m && withPadding({ padding: props.padding.m })}
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    ${props => props.margin.l && withMargin({ margin: props.margin.l })}
    ${props => props.padding.l && withPadding({ padding: props.padding.l })}
  }
`;

const defaultSpacing = {
  xs: "0",
};

const applyDefaults = v => {
  return { ...defaultSpacing, ...v };
};

const Spacer = ({
  padding = defaultSpacing,
  margin = defaultSpacing,
  children,
  wrap = false,
  flex = false,
}) => (
  <Container
    margin={applyDefaults(margin)}
    padding={applyDefaults(padding)}
    shouldWrap={wrap}
    flex={flex}
  >
    {children}
  </Container>
);

Spacer.propTypes = {
  padding: PropTypes.shape({
    xs: PropTypes.string,
    s: PropTypes.string,
    m: PropTypes.string,
    l: PropTypes.string,
  }),
  margin: PropTypes.shape({
    xs: PropTypes.string,
    s: PropTypes.string,
    m: PropTypes.string,
    l: PropTypes.string,
  }),
  wrap: PropTypes.bool,
  flex: PropTypes.bool,
};

export default Spacer;
